import React, { useState } from "react";
import { Link } from "gatsby";

import './blogpostPreview.scss';
import '../styles/fadein.scss';
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import { useTranslation } from "react-i18next";

export const BlogpostPreview = ({ blogpost, locale }) => {
  const { t, i18n } = useTranslation();
    return (
    <div className="blogpostPreview">
      <h3>{blogpost.node.frontmatter.title}</h3>
      <div className="blogpostPreview__date">
          {new Date(blogpost.node.frontmatter.date).toLocaleDateString(i18n.language)}
      </div>
      <p>{blogpost.node.frontmatter.description ? blogpost.node.frontmatter.description : blogpost.node.excerpt}</p>
      <Link to={`${blogpost.node.frontmatter.path}`}>link</Link>
    </div>
  )
}



export const BlogpostPreviewWithEnterance = ({ blogpost, locale }) => {
  const { t, i18n } = useTranslation();
  
  const divRef = React.useRef();
  const { hasEntered } = useIntersectionObserver(divRef)

  return (
  <div 
    ref={divRef} className={`blogpostPreview fade-in-div ${hasEntered ? 'is-visible' : ''}`}
  >
    {
      blogpost.node.frontmatter.frontPageImg
      ?
      <div 
        className={`blogpostPreview__frontpageImg__container`}
      >
        <img
          className={`blogpostPreview__frontpageImg__img`}
          src={blogpost.node.frontmatter.frontPageImg}
          alt={`${blogpost.node.frontmatter.title}-image`}
        />
      </div>
      :
      null
    }
    <h3>{blogpost.node.frontmatter.title}</h3>
    <div className="blogpostPreview__date">
      {new Date(blogpost.node.frontmatter.date).toLocaleDateString(i18n.language)}
    </div>
    <p className='blogpostPreview__description'>{blogpost.node.frontmatter.description ? blogpost.node.frontmatter.description : blogpost.node.excerpt}</p>
    <Link className='blogpostPreview__link' to={`${blogpost.node.frontmatter.path}`}>{t("blogpostPreview.link")}</Link>
  </div>
)
}

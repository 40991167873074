import React, { useState, useEffect } from 'react';

export default function useIntersectionObserver (element) {
    const [hasEntered, setEntered] = useState(false);

    useEffect(() => {
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if(entry.isIntersecting) {
                        setEntered(true);
                        observer.unobserve(entry.target);
                    }
                });
            });
            observer.observe(element.current);

    }, []);

    return {hasEntered}
}